<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="600px"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="formData.remark"
            :rows="3"
            placeholder="输入备注"
            maxlength="200"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "RechargeAuditDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      titleDialog: "充值审核",
      formData: {
        remark: "", // 备注
      }, // 表单数据
      formRules: {},
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formData = {
        ...data,
        remark: "",
      };
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      const params = {
        ...this.formData,
      };
      this.$emit("on-change-status", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 20px 30px;
  }
  // /deep/ .el-form {
  // }
  .el-input {
    width: 300px;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", align: "center", width: "100", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "username",
          align: "center",
          width: "100",
          label: "用户名",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "mobile",
          align: "center",
          label: "手机号",
          width: "160",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistics_business_name",
          align: "center",
          label: "集配中心",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(_vm._s(scope.row.logistics_business_name || "-")),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistics_name",
          align: "center",
          label: "城市仓",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.logistics_name || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "delivery_name",
          align: "center",
          label: "自提点",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.delivery_name || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "amount",
          align: "center",
          label: "充值金额",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "certificate",
          align: "center",
          label: "充值凭证",
          width: "120",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.certificate
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleView(scope.row)
                          },
                        },
                      },
                      [_vm._v("点击查看")]
                    )
                  : _c("span", [_vm._v("-")]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "status_txt",
          align: "center",
          label: "充值状态",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "create_time",
          align: "center",
          label: "添加时间",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "audit_time",
          align: "center",
          label: "审核时间",
          "min-width": "150",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.audit_time || "-"))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "audit_name",
          align: "center",
          label: "审核操作人",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.audit_name || "-"))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "remark",
          align: "center",
          label: "审核备注",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.remark || "-"))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "操作", width: "160", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.status === _vm.MEMBER_RECHARGE_STATUS.PADDING.value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleDelData(scope.row, "delete")
                          },
                        },
                      },
                      [_vm._v("作废")]
                    )
                  : _vm._e(),
                _vm.isAudit &&
                scope.row.status === _vm.MEMBER_RECHARGE_STATUS.PADDING.value
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "15px" },
                        attrs: { type: "text", disable: _vm.disabledAudit },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleDelData(scope.row, "audit")
                          },
                        },
                      },
                      [_vm._v("审核")]
                    )
                  : _vm._e(),
                [
                  _vm.MEMBER_RECHARGE_STATUS.HAS.value,
                  _vm.MEMBER_RECHARGE_STATUS.FAIL.value,
                ].includes(scope.row.status)
                  ? _c("span", [_vm._v("-")])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "新增对公转账",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: {
                "label-width": "190px",
                model: _vm.formData,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "充值手机号：", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入",
                      maxLength: "11",
                      disabled: _vm.disabledMobile,
                    },
                    on: { blur: _vm.onChangeMobile },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mobile", $$v)
                      },
                      expression: "formData.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市仓：", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      readonly: "",
                      placeholder: "输入手机号展示",
                    },
                    model: {
                      value: _vm.logisticsName,
                      callback: function ($$v) {
                        _vm.logisticsName = $$v
                      },
                      expression: "logisticsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自提点：", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      readonly: "",
                      placeholder: "输入手机号展示",
                    },
                    model: {
                      value: _vm.deliveryName,
                      callback: function ($$v) {
                        _vm.deliveryName = $$v
                      },
                      expression: "deliveryName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值金额：", prop: "amount" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "amount", $$v)
                      },
                      expression: "formData.amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值凭证：", prop: "certificate" } },
                [
                  _c("b-file-upload", {
                    attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                    model: {
                      value: _vm.fileList,
                      callback: function ($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
        <el-button @click="onHandleCreate" type="primary">新 增</el-button>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.logistic_business_id"
          @change="onChangeLogisticBusiness"
          filterable
          clearable
          placeholder="选择集配中心"
        >
          <el-option
            v-for="item of logisticList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.logistics_id"
          :loading="loadingSelfLogisticsList"
          filterable
          clearable
          placeholder="选择城市仓"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.username"
          clearable
          placeholder="充值用户名"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input v-model="formData.mobile" clearable placeholder="输入手机号">
        </el-input>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.status"
          filterable
          clearable
          placeholder="充值状态"
        >
          <el-option
            v-for="item in rechargeStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        :isAudit="isAudit"
        :disabledAudit="disabledAudit"
        @on-preview="onHandlePreview"
        @on-change-audit="onHandleAudit"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新增充值记录，弹窗 start -->
    <edit-recharge-log-dialog
      ref="refEditMemberDialog"
      @on-edit-config="ajaxGetTableData"
    ></edit-recharge-log-dialog>
    <!-- 新增充值记录，弹窗 end -->

    <!-- 充值凭证，弹窗 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      @input="fileList = []"
    ></c-preview-file>
    <!-- 充值凭证，弹窗 end -->

    <!-- 充值审核，弹窗 start -->
    <recharge-audit-dialog
      ref="refRechargeAuditDialog"
      @on-change-status="ajaxPostCorporateRechargeAudit"
    ></recharge-audit-dialog>
    <!-- 充值审核，弹窗 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import TableList from "./modules/table-list/index.vue";
import EditRechargeLogDialog from "./modules/edit-recharge-log-dialog/index.vue";
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";
import RechargeAuditDialog from "./modules/recharge-audit-dialog/index.vue";
import { downLoadFlowBlob } from "@/utils/tools/base";
import {
  getCorporateRechargeList,
  postCorporateRechargeAudit,
  postCorporateRechargeExport,
} from "@/api/member/index";
import { MEMBER_RECHARGE_STATUS } from "./utils/enum/index";

export default {
  name: "CorporateRechargeList",
  components: {
    TableList,
    EditRechargeLogDialog,
    CPreviewFile,
    RechargeAuditDialog,
  },
  data() {
    return {
      loadingData: false,
      loadingSelfLogisticsList: false,
      disabledExport: false,
      disabledAudit: false,
      rechargeStatusList: Object.values(MEMBER_RECHARGE_STATUS), // 充值状态列表
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      formData: {
        logistic_business_id: "", // 集配中心id
        store_logistic_id: "", // 城市仓id
        username: "", // 用户名
        mobile: "", // 手机号
        status: "", // 充值状态
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      isAudit: false, // 是否是财务
      dialogVisible: false, // 充值凭证，弹窗是否展示
      fileList: [], // 充值凭证，文件
    };
  },
  watch: {
    /**
     * 如果集配中心发生改变，则重置城市仓数据
     */
    "formData.logistic_business_id"(val) {
      this.cityStoreList = [];
      this.ajaxGetCityStoreList(val);
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetLogisticList();
      this.ajaxGetCityStoreList();
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      this.loadingSelfLogisticsList = true;
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingSelfLogisticsList = false;
      }
    }, 300),
    /**
     * 集配中心发生变化
     */
    onChangeLogisticBusiness(data) {
      this.cityStoreList = [];
      this.formData.logistics_id = "";
      this.ajaxGetCityStoreList(data);
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await getCorporateRechargeList(params);
        const { total, data: list, is_audit: isAudit } = data;
        this.total = total;
        this.tableData = list;
        this.isAudit = isAudit;
      } catch (err) {
        console.log("ajax getCorporateRechargeList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableData();
    },
    /**
     * 导出数据
     */ async onHandleExport() {
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const params = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
          user_name: username,
        };
        delete params.page;
        delete params.pageSize;
        const res = await postCorporateRechargeExport(params);
        downLoadFlowBlob(res, res?.filename);
      } catch (err) {
        console.log("ajax postCorporateRechargeExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 新增
     */
    onHandleCreate() {
      this.$refs.refEditMemberDialog.onInitData();
    },
    /**
     * 审核/作废
     */
    onHandleAudit(data) {
      const params = {
        corporate_recharge_id: data.id,
        status: "",
      };
      // 作废
      if (data.handleType === "delete") {
        params.status = MEMBER_RECHARGE_STATUS.FAIL.value;
        this.ajaxPostCorporateRechargeAudit(params);
      }
      // 审核
      if (data.handleType === "audit") {
        params.status = MEMBER_RECHARGE_STATUS.HAS.value;
        this.$refs.refRechargeAuditDialog.onInitData(params);
      }
    },
    /**
     * 审核/作废, 接口调用
     */
    async ajaxPostCorporateRechargeAudit(params) {
      this.disabledAudit = true;
      try {
        await postCorporateRechargeAudit(params);
        this.ajaxGetTableData();
        // 手动修改数据状态，减少一次接口请求
        // const index = this.tableData.findIndex((item) => item.id === data.id);
        // this.tableData[index].status = AUDIT_STATUS.succeed.value;
        // this.$set(this.tableData[index], "status", AUDIT_STATUS.succeed.value);
        this.$message.success("操作成功");
        this.$refs.refRechargeAuditDialog?.onHandleClose();
      } catch (err) {
        console.log("ajax postCorporateRechargeAudit err", err);
      } finally {
        this.disabledAudit = false;
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 预览充值凭证
     */
    onHandlePreview(row) {
      this.dialogVisible = true;
      this.fileList = [
        {
          name: "充值凭证",
          url: row.certificate,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>

<template>
  <el-dialog
    title="新增对公转账"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        label-width="190px"
        ref="refForm"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="充值手机号：" prop="mobile">
          <el-input
            placeholder="请输入"
            v-model="formData.mobile"
            maxLength="11"
            @blur="onChangeMobile"
            :disabled="disabledMobile"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="城市仓：" required>
          <el-input
            v-model="logisticsName"
            clearable
            readonly
            placeholder="输入手机号展示"
          ></el-input>
        </el-form-item>

        <el-form-item label="自提点：" required>
          <el-input
            v-model="deliveryName"
            clearable
            readonly
            placeholder="输入手机号展示"
          ></el-input>
        </el-form-item>

        <el-form-item label="充值金额：" prop="amount">
          <el-input
            v-model="formData.amount"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        <el-form-item label="充值凭证：" prop="certificate">
          <b-file-upload
            :accepts="['jpg', 'jpeg', 'png']"
            :limit="1"
            v-model="fileList"
          ></b-file-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import {
  postCorporateRechargeCheckMobile,
  postCorporateRechargeSave,
} from "@/api/member/index";
import { validMobile, validMoney } from "@/utils/tools/validate";

export default {
  name: "EditRechargeLogDialog",
  props: {},
  components: {
    BFileUpload,
  },
  data() {
    return {
      dialogVisible: false,
      formData: {
        mobile: "", // 充值手机号
        amount: "", // 充值金额
        certificate: "", // 充值凭证
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      logisticsName: "", // 城市仓名称
      deliveryName: "", // 字体点名称
      fileList: [], // 凭证文件列
      submitLoading: false, // 提交按钮禁用
      disabledMobile: false, // 手机号输入框禁用
      formRules: {
        mobile: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!validMobile(value)) {
                callback(new Error("请输入符合规范的手机号！"));
                this.logisticsName = "";
                this.deliveryName = "";
              }
              callback();
            },
          },
        ],
        amount: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入金额！"));
              }
              if (!Number(value) || !validMoney(value)) {
                callback(new Error("大于0，允许两位小数点"));
              }
              callback();
            },
          },
        ],
        /**
         * 充值凭证
         */
        certificate: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              // console.log("🆒 fileList 111", this.formData, this.fileList);
              if (!this.fileList.length) {
                callback(new Error("请上传充值凭证！"));
              }
              callback();
            },
          },
        ],
      }, // 表单校验
    };
  },
  mounted() {},
  methods: {
    /**
     * 初始化
     */
    onInitData() {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.formData = {
        ...this.formDataClone,
      };
      this.logisticsName = "";
      this.deliveryName = "";
      this.fileList = [];
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        // console.log("🆒 onHandleSubmit", this.formData);
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        this.ajaxPostCorporateRechargeSave();
      });
    },
    /**
     * 修改了手机号
     */
    onChangeMobile(e) {
      const data = e.target.value;
      // 符合条件的触发接口查询
      validMobile(data) && this.ajaxPostCorporateRechargeCheckMobile(data);
    },
    /**
     * 手机号校验是否会员，返回信息
     */
    async ajaxPostCorporateRechargeCheckMobile(data) {
      // 接口调用时，禁用输入
      this.disabledMobile = true;
      const params = {
        mobile: data,
      };
      try {
        const { data } = await postCorporateRechargeCheckMobile(params);
        this.logisticsName = data?.logistics?.name || "";
        this.deliveryName = data?.delivery?.name || "";
      } catch (err) {
        console.log("ajax postCorporateRechargeCheckMobile err", err);
      } finally {
        // 解除禁用
        this.disabledMobile = false;
      }
    },
    /**
     * 会员充值，新增
     */
    async ajaxPostCorporateRechargeSave() {
      const { mobile, amount } = this.formData;
      const params = {
        mobile,
        amount,
        certificate: this.fileList[0]?.url,
      };
      try {
        await postCorporateRechargeSave(params);
        this.$message.success("新增成功");
        this.onHandleClose();
        this.$emit("on-edit-config");
      } catch (err) {
        console.log("ajax postCorporateRechargeSave err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 30px 20px 20px;
    /deep/ .el-form {
      &-item {
        .el-select,
        .el-input {
          width: 350px;
        }
        &__content {
          display: flex;
          line-height: normal;
        }
        .full-name {
          position: relative;
          left: 10px;
          max-width: 150px;
          line-height: 36px;
          @include ellipsis-lines;
          color: red;
        }
      }
    }
  }
}
</style>

/** 审核状态 */
export const AUDIT_STATUS = {
  waiting: {
    value: 0,
    label: '审核中'
  },
  succeed: {
    value: 1,
    label: '已审核'
  },
}

/** 审核状态 */
export const LOGISTICS_STATUS = {
  disabled: {
    value: '0',
    label: '禁用'
  },
  common: {
    value: '1',
    label: '启用'
  },
  all: {
    value: 'all',
    label: '全部'
  }
}

/** 充值状态 */
export const MEMBER_RECHARGE_STATUS = {
  PADDING: {
    value: 0,
    label: '未审核'
  },
  HAS: {
    value: 1,
    label: '已充值'
  },
  FAIL: {
    value: 2,
    label: '作废'
  }
}

<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="username"
      align="center"
      width="100"
      label="用户名"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="mobile" align="center" label="手机号" width="160">
    </el-table-column>
    <el-table-column
      prop="logistics_business_name"
      align="center"
      label="集配中心"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistics_business_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="logistics_name"
      align="center"
      label="城市仓"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistics_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="delivery_name"
      align="center"
      label="自提点"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.delivery_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="amount"
      align="center"
      label="充值金额"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="certificate"
      align="center"
      label="充值凭证"
      width="120"
    >
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.certificate"
          type="text"
          @click="onHandleView(scope.row)"
          >点击查看</el-button
        >
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="status_txt"
      align="center"
      label="充值状态"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="create_time"
      align="center"
      label="添加时间"
      min-width="120"
    >
    </el-table-column>
    <el-table-column
      prop="audit_time"
      align="center"
      label="审核时间"
      min-width="150"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.audit_time || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="audit_name"
      align="center"
      label="审核操作人"
      min-width="100"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.audit_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="remark"
      align="center"
      label="审核备注"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.remark || "-" }}</span>
      </template>
    </el-table-column>

    <el-table-column label="操作" width="160" align="center">
      <template slot-scope="scope">
        <!-- 未审核，展示作废 start -->
        <el-button
          v-if="scope.row.status === MEMBER_RECHARGE_STATUS.PADDING.value"
          type="text"
          @click="onHandleDelData(scope.row, 'delete')"
          >作废</el-button
        >
        <!-- 未审核，展示作废 end -->

        <!-- 只有财务展示操作栏 start -->
        <el-button
          type="text"
          style="margin-left: 15px"
          v-if="
            isAudit && scope.row.status === MEMBER_RECHARGE_STATUS.PADDING.value
          "
          :disable="disabledAudit"
          @click="onHandleDelData(scope.row, 'audit')"
          >审核</el-button
        >
        <!-- 只有财务展示操作栏 end -->

        <span
          v-if="
            [
              MEMBER_RECHARGE_STATUS.HAS.value,
              MEMBER_RECHARGE_STATUS.FAIL.value,
            ].includes(scope.row.status)
          "
          >-</span
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { MEMBER_RECHARGE_STATUS } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    /**
     * 是否是财务，只有财务，展示操作栏
     */
    isAudit: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否禁用审核按钮
     */
    disabledAudit: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MEMBER_RECHARGE_STATUS,
    };
  },
  methods: {
    /**
     * 审核/作废
     */
    onHandleDelData(row, handleType) {
      const params = {
        ...row,
        handleType,
      };
      if (handleType === "delete") {
        this.$confirm("确定要作废这条数据吗？", "提示", {
          center: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          customClass: "jn-confirm-message-style",
          iconClass: "el-icon-warning",
        })
          .then(() => {
            this.$emit("on-change-audit", params);
          })
          .catch();
      } else {
        this.$emit("on-change-audit", params);
      }
    },
    /**
     * 预览充值凭证
     */
    onHandleView(row) {
      this.$emit("on-preview", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
